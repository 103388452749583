import styled from '@emotion/styled'
import { breakpoints, colors, container } from '../../styles/theme'
import Image from 'gatsby-image'

export default styled.main`
  position: relative;

  padding-top: 1px; // collapse content margin

  background: linear-gradient(
      to bottom,
      ${colors.lightGrey}FF 0,
      ${colors.lightGrey}00 80px// safari fix
    ),
    ${colors.white};
`

export const PageContainer = styled.div`
  ${container};
  max-width: ${p => p.maxWidth || breakpoints.large}px;

  padding: 0 16px 40px;

  @media (min-width: ${p => p.maxWidth || breakpoints.large}px) {
    padding-left: ${p => (p.padded ? '48px' : '24px')};
    padding-right: ${p => (p.padded ? '48px' : '24px')};
  }

  background: ${colors.white};
  border-top: 4px solid ${colors.accent};
  @media (min-width: ${p => p.maxWidth || breakpoints.large}px) {
    margin-top: -40px;
  }
`

export const ContentImage = styled(Image)`
  margin: 24px auto;
`
