import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/SEO'

import Layout from '../components/layout'
import PageHero from '../components/shared/page-hero'
import PageBody, { PageContainer } from '../components/shared/page-body'

const LoyaltyPage = ({ data }) => (
  <Layout>
    <Seo title="Loyalty Scheme" path="/loyalty-scheme/" />
    <PageHero image={data.hero.childImageSharp} title="Loyalty Scheme" />
    <PageBody>
      <PageContainer maxWidth={760} padded>
        <h1 className="heading--h1">Loyalty Scheme</h1>
        <h2 className="heading--h2">Premier</h2>
        <p>
          Order 3 suits in a 12 month period. and receive a £50 Gift Voucher and
          10% discount on all future orders for life.
        </p>
        <h2 className="heading--h2">Executive</h2>
        <p>
          Order 4 suits in a 12 month period.and receive a £100 Gift Voucher and
          12.5% discount on all future orders for life.
        </p>
        <h2 className="heading--h2">Ambassador</h2>
        <p>
          Order 6 suits in a 12 month period and receive a £100 Gift Voucher and
          15% discount on all future orders for life.
        </p>
      </PageContainer>
    </PageBody>
  </Layout>
)

export const query = graphql`
  {
    hero: file(relativePath: { eq: "img/loyalty-scheme-hero.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default LoyaltyPage
