import React from 'react'
import Image from 'gatsby-image'

const PageHero = ({ image, alt, title }) => (
  <Image
    {...image}
    alt={alt || title}
    style={{ minHeight: '240px', maxHeight: '500px' }}
    imgStyle={{ fontFamily: 'object-fit: cover' }}
  />
)

export default PageHero
